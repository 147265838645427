import { useGetProjectsProjectId } from "../.rest-hooks/projects";
import type { AppProjectGranularPermission } from "../.rest-hooks/types";

type ProjectPermissionExpression = (
  permissions: Partial<Record<AppProjectGranularPermission, boolean>>,
) => boolean | undefined;

export interface UseHasProjectPrivilegeParams {
  requestedPermissions:
    | readonly AppProjectGranularPermission[]
    | ProjectPermissionExpression;
}

export const useHasProjectPrivilege = (
  projectId: string | undefined,
  { requestedPermissions }: UseHasProjectPrivilegeParams,
) => {
  return useGetProjectsProjectId(projectId!, {
    query: {
      enabled: !!projectId,
      select: (data) => {
        const { permissions } = data.data.meta;
        return typeof requestedPermissions === "function"
          ? !!requestedPermissions(
              Object.fromEntries(
                permissions.map((permission) => [permission, true]),
              ),
            )
          : requestedPermissions.every((permission) =>
              permissions.includes(permission),
            );
      },
    },
  });
};
