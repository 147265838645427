import { IconButton } from "@kablamooss/geo-ds-core-components";
import { css, styled } from "styled-components";
import { useBoolean } from "usehooks-ts";
import { NotAccepted, Accepted, PreviouslyAccepted } from "../../../icons";
import type { Run } from "../../../types";
import AcceptMultipleScenarioRunModal from "./AcceptMultipleScenarioRunModal";
import UnacceptMultipleScenarioRunModal from "./UnacceptMultipleScenarioRunModal";

const StyledAcceptedIconButton = styled(IconButton)`
  color: ${(p) => p.theme.colors.primary.icon};
`;

const StyledNotAcceptedIconButton = styled(IconButton)<{
  wasAccepted: boolean;
}>`
  color: ${(p) => p.theme.colors.neutrals.iconWeak};

  ${(p) =>
    p.wasAccepted &&
    css`
      color: ${p.theme.colors.primary.backgroundWeak};

      &:hover {
        color: ${p.theme.colors.primary.backgroundWeakPressed};
      }
    `}
`;
interface AcceptMultipleScenarioRunButtonProps {
  run: Run;
}

const AcceptMultipleScenarioRunButton = ({
  run,
}: AcceptMultipleScenarioRunButtonProps) => {
  const { setTrue: open, setFalse: close, value: isOpen } = useBoolean();
  const { accepted, id, name, project, scenario, wasAccepted } = run;

  if (accepted) {
    return (
      <>
        <StyledAcceptedIconButton
          icon={Accepted}
          label="Click to Unaccept run"
          onClick={(e) => {
            open();
            e.preventDefault();
          }}
          variant="ghost"
        />
        <UnacceptMultipleScenarioRunModal
          isOpen={isOpen}
          onClose={close}
          projectId={project.id}
          runId={id}
          runName={name}
        />
      </>
    );
  }

  return (
    <>
      <StyledNotAcceptedIconButton
        icon={wasAccepted ? PreviouslyAccepted : NotAccepted}
        label={
          wasAccepted
            ? "Previously Accepted run. Click to Accept run again."
            : "Click to Accept run."
        }
        onClick={(e) => {
          open();
          e.preventDefault();
        }}
        variant="ghost"
        wasAccepted={wasAccepted}
      />
      <AcceptMultipleScenarioRunModal
        isOpen={isOpen}
        onClose={close}
        projectId={project.id}
        runId={id}
        runName={name}
        scenario={scenario}
      />
    </>
  );
};

export default AcceptMultipleScenarioRunButton;
