import {
  Alert,
  ConfirmModal,
  Text,
  showToast,
  type AlertContent,
} from "@kablamooss/geo-ds-core-components";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  getGetProjectsProjectIdQueryKey,
  getGetProjectsQueryKey,
} from "../../../.rest-hooks/projects";
import {
  getGetProjectsProjectIdRunsQueryKey,
  getGetProjectsProjectIdRunsRunIdQueryKey,
  usePostProjectsProjectIdRunsRunIdAccept,
} from "../../../.rest-hooks/runs";
import { getGetTreatmentareasQueryKey } from "../../../.rest-hooks/treatment-areas";
import type { AppRunScenario } from "../../../.rest-hooks/types";
import { DEFAULT_RMP_ERROR_MESSAGE } from "../../../config/constants";
import { getScenarioLabel } from "../../../config/projects";

interface AcceptMultipleScenarioRunModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  runId: string;
  runName: string;
  scenario: AppRunScenario;
}

const AcceptMultipleScenarioRunModal = ({
  isOpen,
  onClose,
  projectId,
  runId,
  runName,
  scenario,
}: AcceptMultipleScenarioRunModalProps) => {
  const [acceptRunError, setAcceptRunError] = useState<AlertContent | null>(
    null,
  );

  const queryClient = useQueryClient();

  const { mutateAsync: acceptScenarioRun } =
    usePostProjectsProjectIdRunsRunIdAccept({
      mutation: {
        onSettled: (_data, _error, variables) => {
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdRunsRunIdQueryKey(
              variables.projectId,
              variables.runId,
            ),
          });

          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdRunsQueryKey(variables.projectId),
          });

          void queryClient.invalidateQueries({
            queryKey: getGetTreatmentareasQueryKey({
              projectId: variables.projectId,
            }),
          });

          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdQueryKey(variables.projectId),
          });

          void queryClient.invalidateQueries({
            queryKey: getGetProjectsQueryKey(),
          });
        },
        onSuccess: () => {
          showToast({
            title: "Run accepted",
            variant: "success",
          });
        },
        onError: () => {
          showToast({
            message: DEFAULT_RMP_ERROR_MESSAGE,
            title: "Unable to Accept run",
            variant: "error",
          });
        },
      },
    });

  const onConfirm = async () => {
    try {
      return await acceptScenarioRun({ projectId, runId });
    } catch (error) {
      setAcceptRunError({
        message: "Try again.",
        title: "Unable to Accept run",
      });
      throw error;
    }
  };

  const onConfirmSuccess = () => {
    onClose();
  };

  return (
    <ConfirmModal
      confirmLabel="Accept run"
      error={
        acceptRunError && (
          <Alert title={acceptRunError.title} variant="error">
            {acceptRunError.message}
          </Alert>
        )
      }
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={onConfirm}
      onConfirmSuccess={onConfirmSuccess}
      title={`Accept ${runName} as the ${getScenarioLabel(
        scenario,
      )} scenario run`}
    >
      <Text size="bodyDefault">
        By Accepting this run, you acknowledge it as an accurate representation
        of the scenario.
      </Text>
    </ConfirmModal>
  );
};

export default AcceptMultipleScenarioRunModal;
