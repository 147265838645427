import { weakStyles } from "@kablamooss/geo-ds-core-components";
import { css } from "styled-components";

export const defaultTag = css`
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  ${weakStyles.border.base}

  &[data-has-hover="true"] {
    &:hover {
      ${weakStyles.background.hover}
    }

    &:focus {
      ${weakStyles.background.active}
    }
  }
`;

export const yellowTag = css`
  color: #504811;
  border-color: rgba(80, 72, 17, 0.1);
  background-color: #f1e790;

  &[data-has-hover="true"] {
    &:hover {
      background-color: #e4db86;
    }

    &:focus {
      background-color: #e1d783;
    }
  }
`;

export const purpleTag = css`
  color: #0b2e71;
  border-color: rgba(11, 46, 113, 0.1);
  background-color: #ced1fc;

  &[data-has-hover="true"] {
    &:hover {
      background-color: #bec4f1;
    }

    &:focus {
      background-color: #bac1ee;
    }
  }
`;

export const blueTag = css`
  color: #002d29;
  border-color: rgba(0, 45, 41, 0.1);
  background-color: #c1efee;

  &[data-has-hover="true"] {
    &:hover {
      background-color: #b2dfde;
    }

    &:focus {
      background-color: #aedcda;
    }
  }
`;
