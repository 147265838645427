import { HOUR } from "@kablamo/kerosene";
import { format } from "date-fns/format";
import { intervalToDuration } from "date-fns/intervalToDuration";
import { fromZonedTime } from "date-fns-tz";
import isEmpty from "lodash/isEmpty";
import { timeZone } from "../../config/constants";

type DateFormat =
  | "timeAndDate"
  | "fullTimeAndDate"
  | "timeOnly"
  | "dateOnly"
  | "timeAndTimezone"
  | "yearOnly"
  | "monthOnly"
  | "dayOnly";

export const getFormattedDate = (time: string | Date | number): string => {
  const date = new Date(time);
  return new Intl.DateTimeFormat("en-AU", {
    hourCycle: "h23",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(date);
};

export const getFormattedTime = (time: Date) => {
  const date = new Date(time);
  return new Intl.DateTimeFormat("en-AU", {
    timeStyle: "short",
    hourCycle: "h23",
  }).format(date);
};

export const getFormattedDateAndTime = (date: string | Date | number) => {
  return format(new Date(date), "HH:mm, dd/MM/yyyy");
};

export const getLongFormattedDateAndTime = (date: number) => {
  return format(new Date(date), "MMMM d',' yyyy 'at' h:mmaaa");
};

export default function formatDate(
  date: string | Date | number,
  dateFormat: DateFormat,
) {
  switch (dateFormat) {
    case "timeAndDate":
      return new Date(date)
        .toLocaleString("en-AU", {
          hourCycle: "h23",
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })
        .toUpperCase();

    case "fullTimeAndDate":
      return new Date(date)
        .toLocaleString("en-AU", {
          hourCycle: "h23",
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .toUpperCase();

    case "timeOnly":
      return new Date(date)
        .toLocaleString("en-AU", {
          hourCycle: "h23",
          hour: "2-digit",
          minute: "2-digit",
        })
        .toUpperCase();

    case "dateOnly":
      return new Date(date)
        .toLocaleString("en-AU", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        })
        .toUpperCase();

    case "timeAndTimezone":
      return new Date(date).toLocaleString("en-AU", {
        hour: "2-digit",
        hourCycle: "h23",
        minute: "2-digit",
        timeZoneName: "short",
      });

    case "yearOnly":
      return new Date(date).toLocaleString("en-AU", {
        year: "numeric",
      });

    case "monthOnly":
      return new Date(date).toLocaleString("en-AU", {
        month: "2-digit",
      });

    case "dayOnly":
      return new Date(date).toLocaleString("en-AU", {
        day: "2-digit",
      });
  }
}

export const formatDateForInput = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

const WEATHER_LIBRARY_DATE_FORMATTER = new Intl.DateTimeFormat("en-AU", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  timeZone,
});

/**
 * Accepts a date in yyyy-MM-dd format and returns in dd/MM/yyyy (Australia/Sydney)
 * @param date
 */
export const formatWeatherLibraryDate = (date: string) => {
  return WEATHER_LIBRARY_DATE_FORMATTER.format(fromZonedTime(date, timeZone));
};

/**
 * Returns whether the `testTime` is within the `recencyInterval` of the `referenceTime` (most often this will be the current time).
 * @param referenceTime
 * @param testTime ISO8601 timestamp
 * @param recencyInterval Duration in milliseconds for which something is considered recent
 */
export const isRecent = (
  referenceTime: string | number,
  testTime: string,
  recencyInterval = 12 * HOUR,
) => {
  const reference = new Date(referenceTime).getTime() - recencyInterval;
  return new Date(testTime).getTime() > reference;
};

/**
 * Returns time passed for the `testTime` since the `recencyInterval` (most often this will be the current time).
 * @param referenceTime
 * @param testTime ISO8601 timestamp
 */

export const getTimePassedShort = (
  referenceTime: string | number,
  testTime: string,
): string => {
  const suffix = "ago";
  const interval = intervalToDuration({
    start: new Date(testTime),
    end: new Date(referenceTime),
  });

  if (isEmpty(interval)) {
    throw new Error("invalid date");
  }

  let timeString = "";

  if (interval.years) {
    return `${interval.years}y ${suffix}`;
  }

  if (interval.months) {
    return ` ${interval.months}months ${suffix}`;
  }

  if (interval.days) {
    timeString += ` ${interval.days}d`;
  }

  if (interval.hours) {
    timeString += ` ${interval.hours}h`;
  }

  if (interval.minutes) {
    timeString += ` ${interval.minutes}m`;
  }

  return timeString
    ? `${timeString.trim()} ${suffix}`
    : `${interval.seconds}s ${suffix}`;
};
