import { useMergedRefs } from "@kablamo/kerosene-ui";
import { Text } from "@kablamooss/geo-ds-core-components";
import { useButton } from "@react-aria/button";
import { forwardRef, useRef } from "react";
import styled, { type css } from "styled-components";
import type { AppRunScenario } from "../../../.rest-hooks/types";
import { blueTag, defaultTag, purpleTag, yellowTag } from "./styles";

type RunTagVariantStyles = Record<AppRunScenario, ReturnType<typeof css>>;

export const runTagVariantStyles: RunTagVariantStyles = {
  CR: yellowTag,
  FR: purpleTag,
  FT: blueTag,
  MS: blueTag,
  RR: purpleTag,
  RT: blueTag,
  PM: blueTag,
  FM: blueTag,
};

interface StyledRunTagProps {
  "data-has-hover": boolean;
  variant?: AppRunScenario;
}

const StyledRunTag = styled.div<StyledRunTagProps>`
  display: flex;
  align-items: center;
  padding: 0.125rem 0.5rem;
  width: fit-content;
  max-width: 100%;
  height: 1.5rem;
  border: 1px solid;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;

  ${defaultTag}

  ${(p) => p.variant && runTagVariantStyles[p.variant]}
`;

const StyledText = styled(Text)`
  white-space: nowrap;
`;

interface RunTagProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  onClick?: () => void;
  variant?: AppRunScenario;
}

const RunTag = (
  {
    children,
    "data-testid": dataTestId,
    onClick: onClickRunTag,
    variant,
    ...props
  }: RunTagProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const interactiveRef = useRef<HTMLDivElement>(null);
  const setRef = useMergedRefs(ref, onClickRunTag ? interactiveRef : undefined);

  const { buttonProps } = useButton(
    {
      ...props,
      onPress: onClickRunTag,
      elementType: "div",
    },
    interactiveRef,
  );

  return (
    <StyledRunTag
      {...props}
      {...(onClickRunTag ? buttonProps : undefined)}
      data-has-hover={!!onClickRunTag}
      data-testid={dataTestId}
      ref={setRef}
      variant={variant}
      onClick={(e) => {
        onClickRunTag?.();
        e.stopPropagation();
      }}
    >
      <StyledText size="buttonDefault">{children}</StyledText>
    </StyledRunTag>
  );
};

export default forwardRef(RunTag);
