import {
  IconButton,
  StaticIconWrapper,
  Tooltip,
} from "@kablamooss/geo-ds-core-components";
import { css, styled } from "styled-components";
import { useBoolean } from "usehooks-ts";
import {
  getProjectRunAcceptanceLabel,
  getProjectRunAcceptedLabel,
  isStatewideProject,
} from "../../../config/projects";
import { NotAccepted, Accepted, PreviouslyAccepted } from "../../../icons";
import type { Run } from "../../../types";
import AuthoriseRunModal from "../../statewide/Runs/AuthoriseRun/AuthoriseRunModal";
import AcceptScenarioRunModal from "./AcceptScenarioRunModal";

const StyledIcon = styled.div`
  padding-right: 0.5rem;
`;

const StyledIconButton = styled(IconButton)<{ wasAccepted: boolean }>`
  margin-left: -0.5rem;
  color: ${(p) => p.theme.colors.neutrals.iconWeak};

  ${(p) =>
    p.wasAccepted &&
    css`
      color: ${p.theme.colors.primary.backgroundWeak};

      &:hover {
        color: ${p.theme.colors.primary.backgroundWeakPressed};
      }
    `}
`;

interface AcceptScenarioRunButtonProps {
  run: Run;
}

const AcceptScenarioRunButton = ({ run }: AcceptScenarioRunButtonProps) => {
  const { setTrue: open, setFalse: close, value: isOpen } = useBoolean();
  const { accepted, id, name, project, scenario, wasAccepted } = run;

  const isStatewide = isStatewideProject(project.type);

  const runAcceptanceLabel = getProjectRunAcceptanceLabel(project.type);
  const runAcceptedLabel = getProjectRunAcceptedLabel(project.type);

  if (accepted) {
    return (
      <StyledIcon>
        <Tooltip message={runAcceptedLabel} placement="bottom">
          <StaticIconWrapper icon={Accepted} variant="primary" />
        </Tooltip>
      </StyledIcon>
    );
  }

  return (
    <>
      <StyledIconButton
        icon={wasAccepted ? PreviouslyAccepted : NotAccepted}
        label={
          wasAccepted
            ? `Previously ${runAcceptedLabel}. Click to ${runAcceptanceLabel} again.`
            : `Click to ${runAcceptanceLabel}.`
        }
        onClick={(e) => {
          e.preventDefault();
          open();
        }}
        variant="ghost"
        wasAccepted={wasAccepted}
      />
      {isStatewide ? (
        <AuthoriseRunModal
          isOpen={isOpen}
          onClose={close}
          projectId={project.id}
          projectType={project.type}
          runId={id}
          runName={name}
        />
      ) : (
        <AcceptScenarioRunModal
          isOpen={isOpen}
          onClose={close}
          projectId={project.id}
          runId={id}
          runName={name}
          scenario={scenario}
        />
      )}
    </>
  );
};

export default AcceptScenarioRunButton;
