import {
  useQuery,
  useQueryClient,
  type QueryKey,
  type UseQueryOptions,
} from "@tanstack/react-query";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import range from "lodash/range";
import deserializeJsonApi, {
  type JsonApi,
} from "../utils/deserializeJsonApi/deserializeJsonApi";
import type { PageQuery } from "../utils/pageQuery/pageQuery";

interface PageResponse<
  TItem extends JsonApi.Item = JsonApi.Item,
  TIncluded extends JsonApi.Included = JsonApi.Included,
> extends JsonApi.ManyResponse<TItem, TIncluded> {
  // TODO: Enforce required
  meta?: {
    totalPages: number;
  };
}

const DEFAULT_PERPAGE = 100;

/**
 * Utility query for fetching all pages for a resource. Use carefully where the number of resources
 * is larger than page size limits, but not unbounded. Will fetch all pages concurrently with
 * `perPage: 100` by default, but can be overridden. Query cache for individual pages will be used.
 *
 * @example
 * ```ts
 * // UseQueryResult<readonly WeatherRegion[]>
 * const allWeatherzonesQuery = useGetAllPages(
 *   getGetWeatherzonesQueryKey,
 *   getWeatherzones,
 *   [],
 * );
 *
 * // UseQueryResult<readonly Run[]>
 * const allRunsQuery = useGetAllPages(
 *   getGetProjectsProjectIdRunsQueryKey,
 *   getProjectsProjectIdRuns,
 *   [projectId],
 * );
 * ```
 *
 * @param getQueryKey Orval generated function for query key of the regular page query
 * @param fetchFn Orval generated function for fetching a regular page
 * @param args Path parameters (if any)
 * @param params Query parameters (if required)
 */
export default function useGetAllPages<
  TPageResponse extends PageResponse,
  TParams extends PageQuery,
  TArgs extends unknown[],
>(
  getQueryKey: (...args: [...TArgs, TParams]) => QueryKey,
  fetchFn: (
    ...params: [...TArgs, TParams?, AxiosRequestConfig?]
  ) => Promise<AxiosResponse<TPageResponse>>,
  args: NoInfer<TArgs>,
  params?: NoInfer<TParams>,
  queryOptions?: Pick<
    UseQueryOptions<JsonApi.DeserializeMany<TPageResponse>["data"]>,
    "enabled" | "placeholderData" | "refetchInterval"
  >,
) {
  const queryClient = useQueryClient();
  const commonParams: TParams = {
    ...(params as TParams),
    page: undefined,
    perPage: params?.perPage ?? DEFAULT_PERPAGE,
  };
  return useQuery<JsonApi.DeserializeMany<TPageResponse>["data"]>({
    ...queryOptions,
    queryFn: async () => {
      // First page is fetched first to determine the total number of pages
      const firstPageParams: TParams = {
        ...commonParams,
        page: 1,
      };
      const firstPageQueryKey = getQueryKey(...args, firstPageParams);
      const firstPageResponse = await queryClient.fetchQuery({
        queryKey: firstPageQueryKey,
        queryFn: ({ signal }) => fetchFn(...args, firstPageParams, { signal }),
      });

      // All other pages are then fetched in parallel
      const otherPages = await Promise.all(
        range(2, firstPageResponse.data.meta!.totalPages + 1).map(
          async (page) => {
            const pageParams: TParams = {
              ...commonParams,
              page,
            };
            const pageQueryKey = getQueryKey(...args, pageParams);
            const pageResponse = await queryClient.fetchQuery({
              queryKey: pageQueryKey,
              queryFn: ({ signal }) => fetchFn(...args, pageParams, { signal }),
            });
            return deserializeJsonApi(pageResponse.data);
          },
        ),
      );
      return [
        deserializeJsonApi(firstPageResponse.data),
        ...otherPages,
      ].flatMap((page) => page.data);
    },
    queryKey: [...getQueryKey(...args, commonParams), "all"],
  });
}
