import { StaticIconWrapper } from "@kablamooss/geo-ds-core-components";
import { styled } from "styled-components";
import {
  canAcceptMultipleRuns,
  getProjectRunHasAcceptanceAction,
} from "../../../config/projects";
import { AcceptedDisabled } from "../../../icons";
import type { Run } from "../../../types";
import AcceptMultipleScenarioRunButton from "./AcceptMultipleScenarioRunButton";
import AcceptScenarioRunButton from "./AcceptScenarioRunButton";

const StyledIcon = styled.div`
  padding-right: 0.5rem;
`;

const StyledButtonIcon = styled.div`
  margin-left: -0.5rem;
`;

interface AcceptScenarioRunProps {
  run: Run;
}

const AcceptScenarioRun = ({ run }: AcceptScenarioRunProps) => {
  const { project, scenario, status, qaStatus } = run;
  const projectType = project.type;

  if (
    !getProjectRunHasAcceptanceAction({
      projectType,
      scenario,
      status,
      qaStatus,
    })
  ) {
    return (
      <StyledIcon>
        <StaticIconWrapper icon={AcceptedDisabled} variant="disabled" />
      </StyledIcon>
    );
  }

  if (canAcceptMultipleRuns(projectType)) {
    return (
      <StyledButtonIcon>
        <AcceptMultipleScenarioRunButton run={run} />
      </StyledButtonIcon>
    );
  }

  return <AcceptScenarioRunButton run={run} />;
};

export default AcceptScenarioRun;
