import {
  Field,
  Select,
  TextInput,
  type FormControlOption,
} from "@kablamooss/geo-ds-core-components";
import { useId } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { styled } from "styled-components";
import { AppRunType } from "../../../.rest-hooks/types";
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH_ERROR_MESSAGE,
} from "../../../config/constants";

const StyledAddFireBehaviourForm = styled.div`
  display: grid;
  gap: 1rem;
`;

const runTypeOptions: FormControlOption[] = [
  {
    label: "Fire Behaviour run",
    value: AppRunType.Phoenix,
  },
  {
    label: "Impact Analysis run",
    value: AppRunType.PhoenixPostprocessing,
  },
];

export interface CloneScenarioRunModalFormValues {
  description: string;
  projectId: string;
  runType: FormControlOption<AppRunType> | null;
}

interface GetDefaultValuesProps {
  hasRunTypeSelection: boolean;
}

export const getDefaultValues = ({
  hasRunTypeSelection,
}: GetDefaultValuesProps): CloneScenarioRunModalFormValues => {
  return {
    description: "",
    projectId: "",
    runType: hasRunTypeSelection
      ? null
      : {
          label: "Fire Behaviour run",
          value: AppRunType.Phoenix,
        },
  };
};

interface CloneScenarioRunModalFormProps {
  hasRunTypeSelection: boolean;
}

const CloneScenarioRunModalForm = ({
  hasRunTypeSelection,
}: CloneScenarioRunModalFormProps) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<CloneScenarioRunModalFormValues>();

  const accessibilityId = useId();

  return (
    <StyledAddFireBehaviourForm>
      {hasRunTypeSelection && (
        <Controller
          control={control}
          name="runType"
          render={({ field, fieldState }) => (
            <Select
              {...field}
              id={`${accessibilityId}-${field.name}`}
              error={fieldState.error}
              label="To create a new:"
              disabled={isSubmitting}
              options={runTypeOptions}
              placeholder="Select"
              validationStatus={fieldState.error && "error"}
            />
          )}
          rules={{
            validate: (value) => !!value || "Required",
          }}
        />
      )}
      <Controller
        control={control}
        name="description"
        render={({ field, fieldState: { error } }) => (
          <Field
            error={error}
            htmlFor={`${accessibilityId}-${field.name}`}
            label="Description"
            hint={`Describe why you are adding a new run within ${DESCRIPTION_MAX_LENGTH} characters`}
          >
            <TextInput
              {...field}
              disabled={isSubmitting}
              id={`${accessibilityId}-${field.name}`}
              validationStatus={error && "error"}
            />
          </Field>
        )}
        rules={{
          required: "Description is required",
          maxLength: {
            value: DESCRIPTION_MAX_LENGTH,
            message: DESCRIPTION_MAX_LENGTH_ERROR_MESSAGE,
          },
        }}
      />
    </StyledAddFireBehaviourForm>
  );
};

export default CloneScenarioRunModalForm;
