import {
  Alert,
  ModalForm,
  onPromise,
  showToast,
  type AlertContent,
} from "@kablamooss/geo-ds-core-components";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FormProvider, useForm, type SubmitHandler } from "react-hook-form";
import {
  getGetProjectsProjectIdRunsQueryKey,
  usePostProjectsProjectIdRunsRunIdClone,
} from "../../../.rest-hooks/runs";
import { DEFAULT_RMP_ERROR_MESSAGE } from "../../../config/constants";
import type { ProjectParentHref } from "../../../config/projects";
import type { Run } from "../../../types";
import type { CloneScenarioRunModalFormValues } from "./CloneScenarioRunModalForm";
import CloneScenarioRunModalForm, {
  getDefaultValues,
} from "./CloneScenarioRunModalForm";

interface CloneScenarioRunModalProps {
  hasRunTypeSelection: boolean;
  isOpen: boolean;
  onClose: () => void;
  parentHref: ProjectParentHref;
  projectId: string;
  run: Run;
}

const CloneScenarioRunModal = ({
  hasRunTypeSelection,
  isOpen,
  onClose,
  parentHref,
  projectId,
  run,
}: CloneScenarioRunModalProps) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [formError, setFormError] = useState<AlertContent | null>(null);

  const form = useForm<CloneScenarioRunModalFormValues>({
    mode: "onChange",
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultValues({ hasRunTypeSelection }));
  }, [isOpen, hasRunTypeSelection, reset]);

  const { mutateAsync: cloneRun } = usePostProjectsProjectIdRunsRunIdClone({
    mutation: {
      onSettled: (_data, _error, variables) => {
        void queryClient.invalidateQueries({
          queryKey: getGetProjectsProjectIdRunsQueryKey(variables.projectId),
        });
      },
      onSuccess: (data) => {
        const runId = data.data.data.id;

        void router.push(
          `${parentHref}/${projectId}/scenarios/${runId}/inputs/general`,
        );

        showToast({
          title: "Run cloned",
          variant: "success",
        });
      },
      onError: () => {
        showToast({
          message: DEFAULT_RMP_ERROR_MESSAGE,
          title: "Unable to clone run",
          variant: "error",
        });
      },
    },
  });

  const onSubmit: SubmitHandler<CloneScenarioRunModalFormValues> = async (
    values,
  ) => {
    try {
      await cloneRun({
        data: {
          description: values.description,
          runType: values.runType!.value,
        },
        projectId,
        runId: run.id,
      });

      onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFormError({
        message: "Try again.",
        title: "Unable to clone run",
      });
    }
  };

  return (
    <ModalForm
      error={
        formError && (
          <Alert title={formError.title} variant="error">
            {formError.message}
          </Alert>
        )
      }
      id="cloneScenarioRun"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      submitLabel="Clone run"
      title={`Clone ${run.name}`}
    >
      <FormProvider {...form}>
        <CloneScenarioRunModalForm hasRunTypeSelection={hasRunTypeSelection} />
      </FormProvider>
    </ModalForm>
  );
};

export default CloneScenarioRunModal;
