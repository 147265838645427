import {
  Alert,
  ConfirmModal,
  showToast,
  Text,
  type AlertContent,
} from "@kablamooss/geo-ds-core-components";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { styled } from "styled-components";
import {
  getGetProjectsProjectIdQueryKey,
  getGetProjectsQueryKey,
} from "../../../../.rest-hooks/projects";
import {
  getGetProjectsProjectIdRunsQueryKey,
  getGetProjectsProjectIdRunsRunIdQueryKey,
  usePostProjectsProjectIdRunsRunIdAccept,
} from "../../../../.rest-hooks/runs";
import { DEFAULT_RMP_ERROR_MESSAGE } from "../../../../config/constants";
import { projectTypeLabels } from "../../../../config/projects";
import type { ProjectType } from "../../../../types";

const StyledModalContent = styled.div`
  display: grid;
  gap: 0.5rem;
  padding-top: 0.5rem;
`;

interface AuthoriseRunModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  projectType: ProjectType;
  runId: string;
  runName: string;
}

const AuthoriseRunModal = ({
  isOpen,
  onClose,
  projectId,
  projectType,
  runId,
  runName,
}: AuthoriseRunModalProps) => {
  const [authoriseRunError, setAuthoriseRunError] =
    useState<AlertContent | null>(null);

  const queryClient = useQueryClient();

  const { mutateAsync: authoriseRun } = usePostProjectsProjectIdRunsRunIdAccept(
    {
      mutation: {
        onSuccess: () => {
          showToast({
            title: "Run authorised",
            variant: "success",
          });
        },
        onError: () => {
          showToast({
            message: DEFAULT_RMP_ERROR_MESSAGE,
            title: "Unable to authorise run",
            variant: "error",
          });
        },

        onSettled: (_data, _error, variables) => {
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdRunsRunIdQueryKey(
              variables.projectId,
              variables.runId,
            ),
          });
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdRunsQueryKey(variables.projectId),
          });
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdQueryKey(variables.projectId),
          });
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsQueryKey(),
          });
        },
      },
    },
  );

  const onConfirm = async () => {
    try {
      return await authoriseRun({ projectId, runId });
    } catch (error) {
      setAuthoriseRunError({
        title: "Unable to authorise run",
        message: DEFAULT_RMP_ERROR_MESSAGE,
      });
      throw error;
    }
  };

  const onConfirmSuccess = () => {
    onClose();
  };

  return (
    <ConfirmModal
      confirmLabel="Authorise run"
      error={
        authoriseRunError && (
          <Alert title={authoriseRunError.title} variant="error">
            {authoriseRunError.message}
          </Alert>
        )
      }
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={onConfirm}
      onConfirmSuccess={onConfirmSuccess}
      title={`Authorise ${runName} as the current ${projectTypeLabels[projectType]} run`}
    >
      <StyledModalContent>
        <Text size="bodyDefault">
          By Authorising this run, you approve using its data as the reference
          run for other projects.
        </Text>
        <Alert
          variant="warning"
          title={`Authorising this run will remove the Authorised status of any
          previously authorised ${projectTypeLabels[projectType]} run`}
        />
      </StyledModalContent>
    </ConfirmModal>
  );
};

export default AuthoriseRunModal;
