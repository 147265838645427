import {
  Alert,
  ConfirmModal,
  Text,
  showToast,
  type AlertContent,
} from "@kablamooss/geo-ds-core-components";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { styled } from "styled-components";
import {
  getGetProjectsProjectIdQueryKey,
  getGetProjectsQueryKey,
} from "../../../.rest-hooks/projects";
import {
  getGetProjectsProjectIdRunsQueryKey,
  getGetProjectsProjectIdRunsRunIdQueryKey,
  useGetProjectsProjectIdRuns,
  usePostProjectsProjectIdRunsRunIdAccept,
} from "../../../.rest-hooks/runs";
import type { AppRunScenario } from "../../../.rest-hooks/types";
import { DEFAULT_RMP_ERROR_MESSAGE } from "../../../config/constants";
import { getScenarioLabel } from "../../../config/projects";
import deserializeJsonApi from "../../../utils/deserializeJsonApi/deserializeJsonApi";

const StyledModalContent = styled.div`
  display: grid;
  gap: 0.5rem;
  padding-top: 0.5rem;
`;

interface AcceptScenarioRunModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  runId: string;
  runName: string;
  scenario: AppRunScenario;
}

const AcceptScenarioRunModal = ({
  isOpen,
  onClose,
  projectId,
  runId,
  runName,
  scenario,
}: AcceptScenarioRunModalProps) => {
  const [acceptRunError, setAcceptRunError] = useState<AlertContent | null>(
    null,
  );

  const queryClient = useQueryClient();

  const { data } = useGetProjectsProjectIdRuns(
    projectId,
    {
      page: 1,
      perPage: 100,
    },
    {
      query: {
        enabled: !!projectId && isOpen,
        select: (response) => deserializeJsonApi(response.data),
      },
    },
  );

  const acceptedRun = data?.data.find(
    (siblingRuns) =>
      siblingRuns.type === "PhoenixPostprocessing" &&
      siblingRuns.accepted === true &&
      siblingRuns.scenario === scenario,
  );

  const { mutateAsync: acceptScenarioRun } =
    usePostProjectsProjectIdRunsRunIdAccept({
      mutation: {
        onSettled: (_data, _error, variables) => {
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdRunsRunIdQueryKey(
              variables.projectId,
              variables.runId,
            ),
          });
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdRunsQueryKey(variables.projectId),
          });
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsProjectIdQueryKey(variables.projectId),
          });
          void queryClient.invalidateQueries({
            queryKey: getGetProjectsQueryKey(),
          });
        },
        onSuccess: () => {
          showToast({
            title: "Run accepted",
            variant: "success",
          });
        },
        onError: () => {
          showToast({
            message: DEFAULT_RMP_ERROR_MESSAGE,
            title: "Unable to Accept run",
            variant: "error",
          });
        },
      },
    });

  const onConfirm = async () => {
    try {
      return await acceptScenarioRun({ projectId, runId });
    } catch (error) {
      setAcceptRunError({
        message: "Try again.",
        title: "Unable to Accept run",
      });
      throw error;
    }
  };

  const onConfirmSuccess = () => {
    onClose();
  };

  return (
    <ConfirmModal
      confirmLabel="Accept run"
      error={
        acceptRunError && (
          <Alert title={acceptRunError.title} variant="error">
            {acceptRunError.message}
          </Alert>
        )
      }
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={onConfirm}
      onConfirmSuccess={onConfirmSuccess}
      title={`Accept ${runName} as the ${getScenarioLabel(
        scenario,
      )} scenario run`}
    >
      <StyledModalContent>
        {acceptedRun && (
          <Alert variant="warning" title="">
            Accepting {runName} will override {acceptedRun.name} as the accepted
            run.
          </Alert>
        )}
        <Text size="subtitleSm">
          By accepting this run, you acknowledge it as the most accurate
          representation of the scenario and would like to clone it for the
          first run of the following scenario.
        </Text>
      </StyledModalContent>
    </ConfirmModal>
  );
};

export default AcceptScenarioRunModal;
